import React, { Component } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';

import Tag from './tag';
import TextSection from './textSection';
import Modal from './modal';
import heroImage from '../images/human-illustration.svg';
import clientsIcon from '../images/clients-icon.svg';
import cvIcon from '../images/cv-icon.svg';
import loopIcon from '../images/loop-icon.svg';
import { URL } from '../utils/utils';
import michaelSzenteVarga006 from '../images/006.jpg';
import michaelSzenteVarga062 from '../images/062.jpg';
import michaelSzenteVarga163 from '../images/163.jpg';
import shareLinkIcon from '../images/share-link-icon.svg';

//Hero Section Styles
const StyledWrapperHero = styled.div`
  margin-bottom: 120px;
  display: flex;

  @media(max-width: 1184px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    justify-items: center;
    width: 95%;
    margin: 0 auto 40px;
  }
`;

const HeroTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 60%;
  margin-right: 12%;

  .responsiveTag {
    display: none;
  }

  @media(max-width: 1184px) {
    text-align: center;
    width: 100%;
    margin-right: 0;

    .responsiveTag {
      display: block;
    }
  }
`;

const Hero = styled.div`
  position: relative;
  width: fit-content;
`;

const HeroImg = styled.img`
  height: 80vh;
  width: auto;
  opacity: 0;

  @media(max-width: 1184px) {
    height: 65vh;
  }
`;

const HeroImgAnimate = posed(HeroImg)({
  visible: {
    opacity: 1,
    transition: { duration: 5000 },
  },
  hidden: {
    opacity: 0,
  }
})

const HeroButtonsWrapper = styled.div`
  margin-top: 28px;

  @media(max-width: 1184px) {
    display: flex;
    justify-content: space-around;
  }
`;

const HeroButton = styled.button`
  padding: 18px 30px;
  font-size: 16px;
  border-radius: 5px;
  background-color: ${props => props.red ? '#ff413d' : '$fff'};
  color: ${props => props.red ? '#fff' : '#14277d'};
  margin-right: 30px;
  cursor: pointer;

  @media(max-width: 1184px) {
    padding: 11px 24px;
    font-size: 11px;
    color: ${props => props.red ? '#fff' : '#0a144b'};
    width: 160px;
    margin-bottom: 15px;
    margin-right: 0;
  }
`;

const AboutDiseaseAnimate = posed.div({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  }
});

const BigDiseaseTitle = styled.div`
  @media(max-width: 1184px) {
    display: none;
  }
`;

const ResponsiveDiseaseTitle = styled.h2`
  display: none;
  @media(max-width: 1184px) {
    display: block;
  }
`;

const DiseaseDot = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
  border: 4px solid #fff;
  background-clip: padding-box;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  z-index: ${props => props.isActive ? '0' : '1'};
  top: ${props => props.positionTop};
  left: ${props => props.positionLeft};
  background-color: ${props => props.red ? '#ff413d' : '#203bbc'};
  height: ${props => props.isActive ? '65px' : null};
  width: ${props => props.isActive ? '65px' : null};
  background-color: ${props => props.isActive ? '#fff' : ''};
  border: ${props => props.isActive ? `25px solid rgba(123, 146, 255, 0.53)` : ''};
  margin-top: ${props => props.isActive ? `-32px` : ''};
  margin-left: ${props => props.isActive ? `-32px` : ''};
  opacity: 0;

  :hover {
    transform: scale(1.5);
  }
`;

const DiseaseDotAnimate = posed(DiseaseDot)({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  }
})

//Learn More Section Styles
const LearnMoreStyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 126px;
  margin-bottom: 120px;

  @media(max-width: 1184px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin: 0 auto;
    background-color: #152882;
    padding: 40px 2.5%;
  }
`;

const ResponsiveLearnMoreHeader = styled.div`
  display: none;

  @media(max-width: 1184px) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

const ImageSection = styled.div`
  display: grid;
  grid-auto-rows: 115px;
  grid-template-areas:
    "imgTop ."
    "imgTop infoButtonTop"
    "infoButtonBottom imgMiddle"
    "imgBottom imgMiddle"
    "imgBottom .";
    grid-gap: 20px;
  justify-content: center;

  @media(max-width: 1184px) {
    grid-template-areas:
    "imgTop imgMiddle";
    grid-auto-rows: auto;
  }
`;

const Img = styled.img`
  width: 250px;
  height: 250px;
  cursor: pointer;
  transition: all 0.3s;
  transform: ${props => props.checked ? 'scale(1.2)' : 'scale(1)'};

  @media(max-width: 1184px) {
    border-radius: 15px;
    transform: none;
    max-width: 100%;
    height: auto;
  }
`;

const ImgTop = styled(Img)`
  border-radius: 25px 5px 5px 5px;
  transform-origin: bottom right;
  grid-area: imgTop;
`;

const ImgMiddle = styled(Img)`
  border-radius: 5px 5px 25px 5px;
  transform-origin: top left;
  grid-area: imgMiddle;
`;

const ImgBottom = styled(Img)`
  border-radius: 5px 5px 5px 25px;
  transform-origin: top right;
  grid-area: imgBottom;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const Icon = styled.img`
`;

const P = styled.p`
  color: #94a7ff;
  line-height: initial;
`;

const InfoButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 100%;
  background-color: #203bbc;
  padding: 26px 50px 31px 45px;
  transition: all 0.3s;
  cursor: pointer;

  :hover {
    transform: scale(1.2);
    background-color: #ff413d;

    P {
      color: #fff;
    }
  }

  @media(max-width: 1184px) {
    display: none;
  }
`;

const InfoButtonTop = styled(InfoButton)`
  border-radius: 5px 25px 5px 5px;
  transform-origin: bottom left;
  grid-area: infoButtonTop;
`;

const InfoButtonBottom = styled(InfoButton)`
  align-self: flex-end;
  transform-origin: center right;
  border-radius: 25px 5px 5px 25px;
  grid-area: infoButtonBottom;
`;

const RightColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-self: baseline;
`;

const TextSectionWrapper = posed.div({
  visible: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition: { duration: 2000 }
  },
  hidden: {
    opacity: 0,
    applyAtStart: { display: 'none' },
  }
});

const ShowCvButton = styled.button`
  display: none;
  padding: 11px 16px;
  font-size: 11px;
  color: #fff;
  width: 160px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  justify-content: center;

  @media(max-width: 1184px) {
    display: flex;
    margin: 15px auto;
  }
`;


const Year = styled.p`
  color: #fff;
`;

//Accordion Section Styles
const AccordionStyledWrapper = styled.div`
  margin-bottom: 120px;

  @media(max-width: 1184px) {
    padding: 40px 2.5%;
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 91px;

  @media(max-width: 1184px) {
    margin-bottom: 26px;
    justify-content: flex-start;
    flex-flow: column;
  }
`;

const AccordionResponsiveHeader = styled.div`
  display: none;
  flex-flow: column;
  margin-bottom: 20px;
  align-items: center;

  @media(max-width: 1184px) {
    display: flex;
  }
`;

const DiseaseName = styled.h2`
  margin: 0;
  font-size: 23px;
  line-height: initial;
  color: #fff;

  @media(max-width: 1184px) {
    font-size: 18px;
  }
`;

const SearchInput = styled.div`
  border: none;
  font-size: 16px;
  align-self: baseline;

  input {
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    margin-left: 20px;
    padding: 4px 0;

    ::placeholder {
      color: #fff;
    }
  }

  @media(max-width: 1184px) {
    display: flex;
    width: 100%;

    input {
      width: 100%;
    }
  }
`;

const LoopImage = styled.img`
  vertical-align: middle;
`;

const Alphabet = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35px, 1fr));
  grid-gap: 6px;
  margin-bottom: 14px;
`;

const Accordion = styled.div``;

const AccordionItem = styled.div`
  cursor: pointer;
  width: 100%;
  background-color: ${props => props.isOpened ? '#ff413d' : '#203bbc'};
  padding: 32px 40px;
  border-radius: 5px;
  margin-bottom: 14px;

  @media(max-width: 1184px) {
    text-align: center;
    border-radius: 15px;
    padding: 20px;
    background-color: ${props => props.isOpened ? '#1d39c0' : '#2a4eff'};
  }
`;

const AccordionItemContent = styled.div`
  margin-top: 57px;
  padding: 0 40px;

  @media(max-width: 1184px) {
    margin-top: 15px;
    padding: 0 15px;
  }
`;

const AccordionItemContentAnimate = posed(AccordionItemContent)({
  visible: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition: { duration: 2000 },
  },
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' }
  }
});

const ContactButton = styled.button`
  background-color: #ff413d;
  padding: 18px 30px;
  font-size: 16px;
  display: flex;
  margin: 30px auto 24px;
  border-radius: 5px;
  color: #fff;

  @media(max-width: 1184px) {
    width: auto;
    margin: 24px auto;
    font-size: 11px;
  }
`;

const ShareLinkButton = styled.button`
  border: 1px solid #fff;
  padding: 8px;
  display: flex;
  margin: 14px 0;
  border-radius: 5px;
  background: transparent;
  display: flex;
  align-items: center;

  p {
    color: #fff;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  @media(max-width: 1184px) {
    width: 160px;
    justify-content: center;
    font-size: 11px;
    color: #fff;
    margin: 0;
  }
`;

const SingleLetter = styled.div`
  padding: 6px 12px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${props => props.isDiseaseOnThatLetter ? 'all' : 'none'};
  background-color: ${props => props.checked ? '#ff413d' : '#203bbc'};
  background-color: ${props => props.isDiseaseOnThatLetter ? '203bbc' : 'transparent'};
`;

const AboutDiseaseAccordion = styled.div`
  display: flex;
  margin-bottom: 43px;

  h2 {
    line-height: initial;
    grid-area: title;
  }

  .imageContainer {
    margin-right: 50px;
    grid-area: img;
    min-width: 270px;

    img {
      min-width: initial;
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }

  @media(max-width: 1184px) {
    margin-bottom: 20px;
    display: grid;
    grid-template-areas:
      "title"
      "img";

    h2 {
      margin-bottom: 12px;
    }

    .imageContainer {
      margin: 12px 0 0;
    }
  }
`;

class HeroLearnAccordionWrapper extends Component {
  state = {
    currentDiseaseId: 0,
    animate: false,
    intervalId: null,
    displayCV: false,
    checkedItem: null,
    diseases: null,
    openedDisease: null,
    openedShortDiseaseDescription: null,
    diseaseLetters: null,
    alphabet: new Map([
      ['A', false], ['B', false], ['C', false], ['D', false], ['E', false], ['F', false], ['G', false], ['H', false], ['I', false], ['J', false], ['K', false], ['L', false], ['M', false], ['N', false], ['O', false], ['P', false], ['Q', false], ['R', false], ['S', false], ['T', false], ['U', false], ['V', false], ['X', false], ['Y', false], ['Z', false]
    ]),
    checkedLetter: 'A',
    searchFilter: '',
    isAlphabetUpdated: false,
    isLinkCopied: false,
    displayHeroElement: false
  }

  componentDidMount() {
    smoothscroll.polyfill();
    axios.get(`${URL}/api/diseases?letter=${this.state.checkedLetter}`)
      .then(response => this.setState({ diseases: response.data }))
      .then(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('erkrankungen') && this.state.diseases) {
          this.openDiseaseAndScroll(urlParams.get('erkrankungen'));
        };
      })
      .catch(err => console.log(err));

    axios.get(`${URL}/api/diseases/letters`)
      .then(response => {
        this.setState({ diseaseLetters: response.data })
      })
      .then(() => {
        this.state.diseaseLetters.forEach(diseaseLetter => {
          this.setState(prevState => ({
            alphabet: prevState.alphabet.set(diseaseLetter.letter.toUpperCase(), true)
          }))
        })
      })
      .then(() => this.setState({ isAlphabetUpdated: true }))
      .catch(err => console.log(err));

    const urlParams = new URLSearchParams(window.location.search).get('scrollPosition');

    if (urlParams) {
      this.scrollToSection(urlParams);
    }

    this.setState({
      displayHeroElement: true
    }, () => {
      let intervalId = setInterval(this.timer, 10000);
      this.setState({ intervalId});
    })
  }

  scrollToSection = (clickedItem) => {
    if (clickedItem === 'homeAnchor') {
      return window.scrollTo(0, 0);
    }
    const element = document.getElementById(clickedItem);
      document.getElementById(clickedItem).scrollIntoView(true);
      setTimeout(() => {
        element.scrollIntoView(true)
      }, 500);
  }

  animateDiseaseChange = (id) => {
    this.setState({
      animate: true
    });

    setTimeout(() => {
      this.setState({
        animate: false,
        currentDiseaseId: id
      })
    }, 600)
  }

  timer = () => {
    const newDiseaseId = this.state.currentDiseaseId + 1;
    if (newDiseaseId >= 6) {
      this.animateDiseaseChange(0);
    } else {
      this.animateDiseaseChange(newDiseaseId);
    }
  }

  changeSelectedDisease = (id) => {
    clearInterval(this.state.intervalId);
    let intervalId = setInterval(this.timer, 10000);
    this.setState({ intervalId: intervalId });
    this.animateDiseaseChange(id);
  }

  checkImage = (clickedImage) => {
    const imageChange = this.state.checkedItem === clickedImage ? null : clickedImage;
    this.setState({
      displayCV: false,
      checkedItem: imageChange
    });
  }

  hideCv = () => {
    this.setState({ displayCV: false });
  }

  openCV = () => {
    this.setState({
      displayCV: true,
      checkedItem: null
    });
  }

  accordionChangeHandler = (accordion, diseaseSlug) => {
    const isCurrentDiseaseBeenClicked = this.state[accordion] === diseaseSlug ? null : diseaseSlug;
    this.setState({
      [accordion]: null,
      isLinkCopied: false
    });

    setTimeout(() => {
      this.setState({ [accordion]: isCurrentDiseaseBeenClicked });
    }, 300);

    if(isCurrentDiseaseBeenClicked) {
      setTimeout(() => {
        const openedAccordionItem = document.getElementById(diseaseSlug).getBoundingClientRect().top + window.scrollY - 70;
        window.scrollTo({
          top: openedAccordionItem,
          behavior: 'smooth'
        });
      }, 400);
    } else {
      setTimeout(() => {
        const accordionSection = document.getElementById('mehrErfahren').getBoundingClientRect().top + window.scrollY - 70;
        window.scrollTo({
          top: accordionSection,
          behavior: 'smooth'
        });
      }, 400);
    }
  }

  updateLetterFilter = letter => {
    let filteredLettersUrl = `${URL}/api/diseases?`;
    const selectedLetter = this.state.checkedLetter === letter ? null : letter;
    this.setState({
      searchFilter: '',
      checkedLetter: selectedLetter}, () => {
        filteredLettersUrl += this.state.checkedLetter ? `letter=${letter}&` : '';

        axios.get(filteredLettersUrl)
          .then(response => {
            this.setState({ diseases: response.data })
          })
          .catch(err => console.log(err));
      })
  }

  copyLinkToAccordionItem = (diseaseSlug) => {
    const pageOffset = window.pageYOffset;
    const hostUrl = window.location.hostname;
    const textField = document.createElement('textarea');
    textField.innerText = `${hostUrl}/erkrankungen/${diseaseSlug}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    window.scrollTo(0, pageOffset);
    this.setState({isLinkCopied: true});
  }

  handleChange = event => {
    let searchUrl = `${URL}/api/diseases?query=`;
    this.setState({
      checkedLetter: null,
      searchFilter: event.target.value
    }, () => {
      searchUrl += this.state.searchFilter;
      axios.get(searchUrl)
        .then(response => {
          this.setState({ diseases: response.data })
        })
        .catch(err => console.log(err));
    });
  }

  openDiseaseAndScroll = diseaseSlug => {
    const firstLetterOfDisease = diseaseSlug[0].toUpperCase();
    let scrollOffset = 20;
    if(window.innerWidth > 1184) {
      scrollOffset = 95;
    }
    this.setState({
      openedDisease: diseaseSlug,
      checkedLetter: firstLetterOfDisease,
      searchFilter: '',
      isLinkCopied: false
    }, () => {
        axios.get(`${URL}/api/diseases?letter=${this.state.checkedLetter}`)
          .then(response => this.setState({ diseases: response.data }))
          .then(() => {
            const openedDisease = document.getElementById(`${diseaseSlug}`).offsetTop
            window.scrollTo({
              top: openedDisease + scrollOffset,
              behavior: 'smooth'
            });
          })
          .catch(err => console.log(err));
    });
  }

  scrollToContactSection = () => {
    let scrollOffset = 30;
    if (window.innerWidth > 1184) {
      scrollOffset = 95;
    }
    const contact = document.getElementById('contact').offsetTop;
    window.scrollTo({
      top: contact + scrollOffset,
      behavior: 'smooth'
    })
  }

  render() {
    let accordionItems;
    let filterLetters;

    const diseaseDescriptions = [
      {
        id: 0,
        title: 'Halsschlagader',
        description: 'Die Carotis Ist das wichtigste Blutgefäss für das Hirn. Bei zunehmender Verengung der Halsschlagader steigt das Risiko für einen Schlaganfall.',
        diseaseSlug: 'halsschlagader-carotis'
      },
      {
        id: 1,
        title: 'TOS',
        description: 'Das Thoracic-Outlet-Syndrom ist ein Kompressionssyndrom im Bereich Schultergürtel/Hals. Dabei werden je nach Fall Nerven, die Arterie (Schlagader) oder Vene zwischen dem Schlüsselbein und der ersten Rippe eingeengt.',
        diseaseSlug: 'thoratic-outlet-syndrom-tos'
      },
      {
        id: 2,
        title: 'Hauptschlagader',
        description: 'Man unterscheidet Brust- und Bauchschlagader (thorakale und abdominale Aorta). Hauptsächliche Erkrankungen sind das Aorten-Aneurysma (Erweiterung), Dissektion (Aufspalten der Gefässwand) und die arteriosklerotische Einengung bis hin zum Verschluss (Leriche-Syndrom).',
        diseaseSlug: 'hauptschlagader-aorta'
      },
      {
        id: 3,
        title: 'Beckenschlagader',
        description: 'Hier haben wir es vorwiegend mit arteriosklerotische Veränderungen und als Folge davon kann es zur Einschränkung der Gehstrecke (Schaufensterkrankheit) zu tun.',
        diseaseSlug: 'beckenschlagader'
      },
      {
        id: 4,
        title: 'Beinschlagader',
        description: 'Die Arterien der Beine haben oft sehr ausgeprägte arteriosklerotische Veränderungen, welche zu Durchblutungsstörungen der Beine führen. Dies führt zur Einschränkung der Gehstrecke (Schaufensterkrankheit) bis hin zum Absterben der Gliedmasse (Raucherbein).',
        diseaseSlug: 'beinschlagader'
      },
      {
        id: 5,
        title: 'Krampfadern',
        description: 'Wenn die oberflächlichen Venen an den Beinen undichte Venenklappen haben, fliesst das Blut nicht mehr nur zum Herzen hin, sondern wieder ins Bein zurück. Dann weiten sich die Venen immer mehr aus. Symptome sind: Wadenkrämpfe, Juckreiz, schwere und müde Beine, oberflächliche Venenentzündungen (Thrombophlebitis), Thrombosen und offene Beine (Ulcus cruris).',
        diseaseSlug: 'krampfadern-varikose'
      }
    ];

    const aboutDisease = (
      <AboutDiseaseAnimate
        pose={this.state.animate ? 'hidden' : 'visible'}>
        <BigDiseaseTitle>
          <TextSection
            title={diseaseDescriptions[this.state.currentDiseaseId].title}
            text={diseaseDescriptions[this.state.currentDiseaseId].description} />
        </BigDiseaseTitle>
        <ResponsiveDiseaseTitle>
          {diseaseDescriptions[this.state.currentDiseaseId].title}
        </ResponsiveDiseaseTitle>
        <HeroButtonsWrapper>
            <HeroButton onClick={this.scrollToContactSection} red>Kontakt aufnehmen</HeroButton>
          <HeroButton
            onClick={() => this.openDiseaseAndScroll(diseaseDescriptions[this.state.currentDiseaseId].diseaseSlug)}>
            Mehr erfahren
          </HeroButton>
        </HeroButtonsWrapper>
      </AboutDiseaseAnimate>
    )

    if (this.state.diseases) {
      accordionItems = this.state.diseases.map(disease => (
        <React.Fragment key={disease.slug}>
          <AccordionItem
            id={disease.slug}
            isOpened={this.state.openedDisease === disease.slug}
            onClick={() => this.accordionChangeHandler('openedDisease', disease.slug)}>
            <DiseaseName>{disease.name}</DiseaseName>
          </AccordionItem>
          <AccordionItemContentAnimate
            pose={this.state.openedDisease === disease.slug ? 'visible' : 'hidden'}>
            {disease.disease_section.map(aboutDisease => (
              <AboutDiseaseAccordion key={aboutDisease.id}>
                {aboutDisease.image_url && <div className="imageContainer">
                  <img src={aboutDisease.image_url} alt={aboutDisease.image_alt_text || aboutDisease.name} />
                </div>}
                <div>
                  <h2>{aboutDisease.name}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: aboutDisease.content }}>
                  </div>
                </div>
              </AboutDiseaseAccordion>
            ))}
            <ShareLinkButton
              onClick={() => this.copyLinkToAccordionItem(disease.slug)}>
              <img src={shareLinkIcon} alt="share link"/>
              <p>{this.state.isLinkCopied ? 'Link kopiert' : 'Teilen'}</p>
            </ShareLinkButton>
            <ContactButton onClick={this.scrollToContactSection}>Kontakt aufnehmen</ContactButton>
          </AccordionItemContentAnimate>
        </React.Fragment>
      ))
    }

    if (this.state.isAlphabetUpdated) {
      filterLetters = Array.from(this.state.alphabet, ([key, value]) => {
        return (
          <SingleLetter
            key={key}
            checked={this.state.checkedLetter === key}
            isDiseaseOnThatLetter={value}
            onClick={() => this.updateLetterFilter(key)}>
            <p>{key}</p>
          </SingleLetter>
        )
      })
    }

    return (
      <React.Fragment>
        <StyledWrapperHero id="heroSection">
          <HeroTextWrapper>
            <Tag tag="Schwerpunkt" />
            <p className="responsiveTag">Schwerpunkt</p>
            {aboutDisease}
          </HeroTextWrapper>
          <Hero>
            <HeroImgAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              id="heroElement"
              src={heroImage}
              alt="hero" />
            <DiseaseDotAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              isActive={0 === this.state.currentDiseaseId}
              onClick={() => this.changeSelectedDisease(0)}
              red
              positionTop='13%'
              positionLeft="51.5%"
            />
            <DiseaseDotAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              isActive={1 === this.state.currentDiseaseId}
              onClick={() => this.changeSelectedDisease(1)}
              red
              positionTop='18.5%'
              positionLeft="61%"
            />
            <DiseaseDotAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              isActive={2 === this.state.currentDiseaseId}
              onClick={() => this.changeSelectedDisease(2)}
              red
              positionTop='37%'
              positionLeft="46.8%"
            />
            <DiseaseDotAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              isActive={3 === this.state.currentDiseaseId}
              onClick={() => this.changeSelectedDisease(3)}
              red
              positionTop='45%'
              positionLeft="60%"
            />
            <DiseaseDotAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              isActive={4 === this.state.currentDiseaseId}
              onClick={() => this.changeSelectedDisease(4)}
              red
              positionTop='57.6%'
              positionLeft="64%"
            />
            <DiseaseDotAnimate
              pose={this.state.displayHeroElement ? 'visible' : 'hidden'}
              isActive={5 === this.state.currentDiseaseId}
              onClick={() => this.changeSelectedDisease(5)}
              blue
              positionTop='76.6%'
              positionLeft="70%"
            />
          </Hero>
        </StyledWrapperHero>
        <React.Fragment>
          <span id="uberMichAnchor" className="anchor"></span>
          <LearnMoreStyledWrapper id="uberMich" className="scrollSection">
              <Modal show={this.state.displayCV} clicked={this.hideCv}>
                <h2>CV Dr. Michael Szente Varga</h2>
                <ul>
                  <li>
                    <Year>2006 - heute</Year>
                    <p>Gefässchirurgische Praxis Klinik Hirslanden und Klinik Im Park, Zürich</p>
                  </li>
                  <li>
                    <Year>2004</Year>
                    <p>Vorstandsmitglied der Schweizerischen Gesellschaft für Gefässchirurgie (SSG)</p>
                  </li>
                  <li>
                    <Year>2002 - 2005</Year>
                    <p>Leiter der Abteilung für Gefässchirurgie
                      Co-Chefarzt Chirurgie B (Herz-, Gefäss- und Thoraxchirurgie)
                        Kantonsspital Luzern (Prof. P. Stulz)</p>
                  </li>
                  <li>
                    <Year>2001 - 2002</Year>
                    <p>Leitender Arzt und Leiter der Gefässchirurgie
                        Herz- und Gefässchirurgie, Universitätsspital Zürich (Prof. M. Turina)</p>
                  </li>
                  <li>
                    <Year>2000</Year>
                    <p>Oberarzt, Herz- und Gefässchirurgie, Universitätsspital Zürich (Prof. M. Turina)</p>
                  </li>
                  <li>
                    <Year>1999</Year>
                    <p>Gastarzt, Thoraxchirurgie, Thoraxklinik Heidelberg, BRD (Prof. H. Dienemann)</p>
                  </li>
                  <li>
                    <Year>1996</Year>
                    <p>Visiting Fellow, Vascular Surgery, UCSF, San Francisco, CA, (Prof. Jerry Goldstone und Prof. Ronald J. Stoney)</p>
                  </li>
                  <li>
                    <Year>1996</Year>
                    <p> Visiting Fellow, Vascular Surgery, Albany Med. College, Albany, NY,(Prof. Robert P. Leather und Prof. Benjamin B. Chang)</p>
                  </li>
                  <li>
                    <Year>1995 - 2000</Year>
                    <p>Oberarzt, Chirurgie, Kantonsspital Baden (Prof. H. Säuberli)</p>
                  </li>
                  <li>
                    <Year>1994</Year>
                    <p>Assistenzarzt, Chirurgie, Kantonsspital Baden (Prof. H. Säuberli)</p>
                  </li>
                  <li>
                    <Year>1990 - 1993</Year>
                    <p>Assistenzarzt Chirurgie, Bezirksspital Zofingen (Dr. Fartab)</p>
                  </li>
                  <li>
                    <Year>1989</Year>
                    <p>Assistenzarzt Chirurgie, Kreisspital Rüti (Dr. P. Bleuler)</p>
                  </li>
                  <li>
                    <Year>1981 - 1988</Year>
                    <p>Medizinstudium und Staatsexamen an der Universität Zürich</p>
                  </li>
                </ul>
              </Modal>
            <ResponsiveLearnMoreHeader>
              <p>Über Mich</p>
              <h2>Dr. Michael Szente Varga</h2>
            </ResponsiveLearnMoreHeader>
            <ImageSection>
              <ImgTop
                top
                checked={'topImage' === this.state.checkedItem}
                onClick={() => this.checkImage('topImage')}
                src={michaelSzenteVarga006} alt="Michael Szente Varga" />
              <InfoButtonTop
                onClick={this.hideCv}>
                <div>
                  <h3>1000+</h3>
                  <P>Operationen</P>
                </div>
                <Icon src={clientsIcon} alt="clients" />
              </InfoButtonTop>
              <InfoButtonBottom
                onClick={this.openCV}>
                <Icon src={cvIcon} alt="CV" />
                <h3>CV</h3>
              </InfoButtonBottom>
              <ImgMiddle
                checked={'middleImage' === this.state.checkedItem}
                onClick={() => this.checkImage('middleImage')}
                src={michaelSzenteVarga062} alt="Michael Szente Varga" />
              <ImgBottom
                checked={'bottomImage' === this.state.checkedItem}
                onClick={() => this.checkImage('bottomImage')}
                src={michaelSzenteVarga163} alt="Michael Szente Varga" />
            </ImageSection>
            <RightColumn>
              <Tag tag="Über Mich" />
              <TextSectionWrapper>
                <TextSection
                  title="Dr. Michael Szente Varga"
                  text="Dr. med. Michael Szente Varga, der in Zürich Medizin studierte, absolvierte seine Ausbildung zur Chirurgie. Für den Facharzt Gefässchirurgie war er zusätzlich an der Vascular Surgery am UCSF in San Francisco und am Albany Medical College in New York, sowie in der Thoraxklinik Heidelberg tätig. Von 2000 bis 2002 war er Leiter der Gefässchirurgie am Universitätsspital Zürich und von 2002 bis 2005 war er Leiter der Abteilung für Gefässchirurgie und Co-Chefarzt Chirurgie am Kantonsspital Luzern. Seit 2006 ist er Belegarzt in der Klinik Hirslanden und Klinik Im Park, Zürich. Als Gefässchirurg ist er spezialisiert auf Durchblutungsstörungen der Becken- und Beinschlagadern, Verengungen der Halsschlagader, Erweiterungen der Bauchschlagader, Krampfadern, Thrombosen und offene Beine. Dabei wendet er verschiedene minimal-invasive Verfahren an, wie die Ballondilatation, Stenteinlagen, und die Krampfaderbehandlung. Seine operativen Verfahren umfassen Bypassoperationen, Thrombendarterektomien, der Ersatz der Bauchschlagader, das Anlegen von AV-Fisteln für die Dialyse sowie das Stripping von Krampfadern. Schwerpunktsmässig steht die Behandlung der Erweiterung der Brust- und Bauchschlagader mittels „Endovaskulären Verfahren“ im Vordergrund. Schon Mitte der 90er Jahre begab er sich zum Erlernen dieser neuen Methode ins Ausland bevor diese in der Schweiz eingeführt wurde. Bis heute hat er weit über 1000 Patienten mit einer „Endovaskulären Aortenprothese“ behandelt. Seit 4 Jahren ist er auch an der Entwicklung einer neuen endovaskulären Prothese involviert." />
              </TextSectionWrapper>
              <ShowCvButton
                onClick={this.openCV}>
                {this.state.displayCVResponsive ? 'CV ausblenden' : 'CV einblenden'}
              </ShowCvButton>
            </RightColumn>
          </LearnMoreStyledWrapper>
        </React.Fragment>
        <React.Fragment>
          <span id="mehrErfahrenAnchor" className="anchor"></span>
          <AccordionStyledWrapper id="mehrErfahren" className="scrollSection">
            <Header>
              <div>
                <Tag tag="Patienteninformation" />
                <TextSection
                  title="Erkrankungen & Therapien" />
              </div>
              <AccordionResponsiveHeader>
                <p>Patienteninformation</p>
                <h2>Erkrankungen & Therapien</h2>
              </AccordionResponsiveHeader>
              <SearchInput>
                <LoopImage src={loopIcon} />
                <input
                  type="search"
                  placeholder="Suchen..."
                  value={this.state.searchFilter}
                  onChange={this.handleChange} />
              </SearchInput>
            </Header>
            <Alphabet>{filterLetters}</Alphabet>
            <Accordion>{accordionItems}</Accordion>
          </AccordionStyledWrapper>
        </React.Fragment>
      </React.Fragment>
    )
  }
}

export default HeroLearnAccordionWrapper;