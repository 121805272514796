import React, { Component } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import Tag from './tag';
import HoverImage from './hoverImage';
import Modal from './modal';
import lyubaChaykowska from '../images/dr_med_lybua_chaykowska.png';
import nicoleBernet from '../images/nicole-bernet.jpg';
import lachatMario from '../images/lachat-mario.png';
import beatriceAmannVesti from '../images/Beatrice Amann-Vesti.png';
import slavicaMarkovicPticek from '../images/Slavica Markovic-Pticek.png';
import medkitIcon from '../images/medkit.svg';

const StyledWrapper = styled.div`
  margin-bottom: 120px;

  @media(max-width: 1184px) {
    margin-bottom: 0;
    padding: 40px 2.5%;
    background-color: #152882;
  }
`;

const MembersWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 120px;
  margin-bottom: 120px;

  @media(max-width: 1184px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-bottom: 24px;
  }
`;

const PartnersWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 120px;

  @media(max-width: 1184px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "images"
      "aboutSection";
    grid-gap: 24px;
    margin-bottom: 24px;

    .partnerHeader {
      grid-area: header;
    }
  }
`;

const H3 = styled.h3`
  margin: 12px 0;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const ResponsiveHeader = styled.div`
  display: none;

  @media(max-width: 1184px) {
    display: flex;
    justify-content: center;
  }
`;

const MainMembers = styled.div`
  display: flex;
  justify-content: space-around;

  @media(max-width: 1184px) {
    flex-wrap: wrap;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`;

const Partners = styled.div`
  display: flex;

  @media(max-width: 1184px) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
  }
`;

const AboutSection = styled.div`
  display: flex;
  flex-flow: column;
`;

const AboutPartners = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 1px;
  min-height: 316px;

  @media(max-width: 1184px) {
    grid-area: aboutSection;
    margin-top: 0;
    min-height: 175px;
  }
`;

const HackParagrap = styled.p`
  margin-bottom: 5px;

  span { visibility: hidden };

  @media(max-width: 1184px) {
    margin-bottom: 45px;
  }
`;

const LinkedinWrapper = styled.div`
  display: flex;
  margin-top: auto;
`;

const LinkedinImage = styled.img`
  margin-right: 20px;
`;

const AboutMemberAnimate = posed.div({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  }
});

const AboutPartnerAnimate = posed.div({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  }
});

const Name = styled.h2`
  display: none;
  @media(max-width: 1184px) {
    display: block;
  }
`;

const Year = styled.p`
  color: #fff;
`;

const CVButton = styled.button`
  padding: 11px 16px;
  font-size: 11px;
  color: #fff;
  width: 160px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  justify-content: center;

  @media(max-width: 1184px) {
    display: flex;
    margin: 15px auto;
  }
`;

class TeamSection extends Component {
  state = {
    currentMemberId: 0,
    currentPartnerId: 0,
    animateMember: false,
    animatePartner: false,
    showCV: false,
  }

  changeSelectedMember = (section, animate, id) => {
    const pageOffset = window.pageYOffset;
    this.setState({
      [animate]: true,
    });

    setTimeout(() => {
      this.setState({
        [animate]: false,
        [section]: id,
      }, () => {
        window.scrollTo(0, pageOffset);
      })
    }, 200)
  }

  closeCV = () => {
    this.setState({showCV: false})
  }

  openCV = () => {
    this.setState({showCV: true})
  }

  render() {
    const teamMembers = [
      {
        id: 0,
        name: 'Dr. med. Lyubov Chaykovska',
        position: 'Oberärztin',
        mainTeamMember: true,
        image: lyubaChaykowska,
        linkToProfile: '',
        image_alt_text: 'Dr. med. Lyubov Chaykovska',
        description:
          <React.Fragment>
            <Name>Dr. med. Lyubov Chaykovska</Name>
            <p>Dr. med. Lyubov Chaykovska erhielt ihre Approbation als Ärztin an der Nationalen Medizinischen Danylo-Halytskyj-Universität Lemberg (Ukraine) und an der Medizinischen Fakultät der Universität Zürich (Schweiz). Sie promovierte mit summa cum laude an der Charité - Universitätsmedizin Berlin (Deutschland). Dr. Chaykovska absolvierte im Jahr 2017 die Europäische Facharztprüfung und erhielt das Diplom Fellow des European Board of Vascular Surgery (FEBVS). Sie ist Autorin von 28 wissenschaftlichen Publikationen in internationalen medizinischen Fachzeitschriften.</p>
          </React.Fragment>,
        cv: <React.Fragment>
          <h2>CV Dr. med. Lyubov Chaykovska</h2>
          <ul>
            <li>
              <Year>11.2017 - aktuell</Year>
              <p>Oberärztin für Gefässchirurgie, Klinik Hirslanden und Klinik Im Park, Zürich, Schweiz</p>
            </li>
            <li>
              <Year>07.2011 – aktuell</Year>
              <p>Gefäßchirurgin und Forscherin, Klinik für Herz- und Gefäßchirurgie, Universitätsspital Zürich, Schweiz </p>
            </li>
            <li>
              <Year>10.2010 – 06.2011</Year>
              <p>Laborleiter, Abteilung für kardiovaskuläre und metabolische Forschung, Hofmann La Roche, Basel, Schweiz</p>
            </li>
            <li>
              <Year>02.2007 - 09.2010</Year>
              <p>Experimenteller Mikrochirurgin und Forscherin, Zentrum für Herz-Kreislauf-Forschung (Center for Cardiovascular Research, CCR), Charité - Universitätsmedizin Berlin, Deutschland</p>
            </li>
            <li>
              <Year>08.2002 - 01.2007</Year>
              <p>Assistenzärztin, Klinik für Allgemeine- und Gefäßchirurgie, Nationale Medizinische Danylo-Halytskyj-Universität Lemberg, Ukraine</p>
            </li>
          </ul>
          <h5>VISITATIONEN UND FELLOWSHIPS</h5>
          <ul>
            <li>
              <Year>März 2013</Year>
              <p>Abteilung für Gefässchirurgie, Karolinska-Universitätskrankenhaus, Stockholm, Sweden (Prof. Linus Blohmé)</p>
            </li>
            <li>
              <Year>April - März 2014</Year>
              <p>Kawaminami Hospital, Tokio, Japan (Dr. Misato Kobayashi)</p>
            </li>
            <li>
              <Year>September - Oktober 2015</Year>
              <p>Gefässchirurgie, Stanford University Medical Center, Stanford, USA (Professor Jason T. Lee)</p>
            </li>
            <li>
              <Year>Juli - August 2015</Year>
              <p>Klinik für Thorax- und Gefässchirurgie, Universitätsklinikum Gent, Gent, Belgien (Prof. Frank E Vermassen).</p>
            </li>
            <li>
              <Year>Februar - März 2015</Year>
              <p>NYU Langones Abteilung für Offene- und Endovaskuläre Gefässchirurgie, New York, USA (Prof. Mark A. Adelman)</p>
            </li>
          </ul>
        </React.Fragment>
      },
      {
        id: 1,
        name: 'Mary Cervasio',
        position: 'Persönliche Assistentin Dr. med. Michael Szente Varga',
        mainTeamMember: true,
        image: nicoleBernet,
        linkToProfile: '',
        image_alt_text: 'Mary Cervasio',
        description: <React.Fragment>
          <Name>Mary Cervasio</Name>
          <HackParagrap>Frau Cervasio begrüsst Sie in unserem Sekretariat und steht Ihnen für Auskünfte und Vereinbarung von Sprechstundenterminen gerne zur Verfügung.<span>und an der Medizinischen Fakultät der Universität Zürich (Schweiz). Sie promovierte mit summa cum laude an der Charité - Universitätsmedizin Berlin (Deutschland). Dr. Chaykovska absolvierte im Jahr 2017 die Europäische Facharztprüfung und erhielt das Diplom Fellow des European Board of Vascular Surgery (FEBVS). Sie ist Autorin von 28 wissenschaftlichen Publikationen in internationalen medizinischen Fachzeitschriften.Publikationen in internationalen medizinischen Fachzeitschriften.</span></HackParagrap>
        </React.Fragment>
      },
      {
        id: 2,
        name: 'Nicole Bernet',
        position: 'Persönliche Assistentin Dr. med. Michael Szente Varga',
        mainTeamMember: true,
        image: nicoleBernet,
        linkToProfile: '',
        image_alt_text: 'Nicole Bernet',
        description: <React.Fragment>
          <Name>Nicole Bernet</Name>
          <HackParagrap></HackParagrap>
        </React.Fragment>
      },
    ];

    const partnerMembers = [
      {
        id: 0,
        name: 'Prof. Dr. med. Mario Louis Lachat',
        position: 'Facharzt für Herz- und thorakale Gefässchirurgie',
        mainTeamMember: false,
        image: lachatMario,
        linkToProfile: 'https://www.hirslanden.ch/en/corporate/doctors/2/prof-dr-med-mario-louis-lachat.html',
        image_alt_text: 'Prof. Dr. med. Mario Louis Lachat',
        description: <React.Fragment>
          <Name>Prof. Dr. med. Mario Louis Lachat</Name>
          <p>Aortenzentrum</p>
          <p>Klinik Hirslanden</p>
          <p>Witellikerstrasse 40</p>
          <p>8032 Zürich</p>
        </React.Fragment>
      },
      {
        id: 1,
        name: 'Prof. Dr. med. Beatrice Amann-Vesti',
        position: 'Fachärztin für Angiologie & Allg. Innere Medizin',
        mainTeamMember: false,
        image: beatriceAmannVesti,
        linkToProfile: 'https://www.hirslanden.ch/de/corporate/aerzte/2/prof-dr-med-beatrice-amann-vesti.html',
        image_alt_text: 'Prof. Dr. med. Beatrice Amann-Vesti',
        description: <React.Fragment>
          <Name>Prof. Dr. med. Beatrice Amann-Vesti</Name>
          <p>Klinik Hirslanden</p>
          <p>Witellikerstrasse 40</p>
          <p>8032 Zürich</p>
        </React.Fragment>
      },
      {
        id: 2,
        name: 'Slavica Markovic-Pticek',
        position: 'Wundexpertin',
        mainTeamMember: false,
        image: slavicaMarkovicPticek,
        linkToProfile: 'http://www.ambulantewundbehandlung.ch',
        image_alt_text: 'Slavica Markovic-Pticek',
        description: <React.Fragment>
          <Name>Slavica Markovic-Pticek</Name>
          <p>Ambulante Wundbehandlung</p>
          <p>Klinik Hirslanden</p>
          <p>Witellikerstrasse 40</p>
          <p>8032 Zürich</p>
        </React.Fragment>
      }
    ]

    const members = teamMembers.map(member => (
      <HoverImage
        key={member.id}
        srcImage={member.image}
        title={member.name}
        text={member.position}
        imageAltText={member.image_alt_text}
        active={member.id === this.state.currentMemberId}
        onClick={() => this.changeSelectedMember('currentMemberId', 'animateMember', member.id)} />
    ));

    const partners = partnerMembers.map(member => (
      <HoverImage
        key={member.id}
        srcImage={member.image}
        title={member.name}
        text={member.position}
        imageAltText={member.image_alt_text}
        active={member.id === this.state.currentPartnerId}
        onClick={() => this.changeSelectedMember('currentPartnerId', 'animatePartner', member.id)}
        />
    ));

    const aboutMember = (
      <AboutMemberAnimate
        pose={this.state.animateMember ? 'hidden' : 'visible'}>
        <H3>{teamMembers[this.state.currentMemberId].name}</H3>
        <div>
          {teamMembers[this.state.currentMemberId].description}
        </div>
        {teamMembers[this.state.currentMemberId].linkToProfile
          ? <LinkedinWrapper>
            <LinkedinImage src={medkitIcon} alt="Linkedin" />
            <p>Linkedin Group Name</p>
          </LinkedinWrapper>
          : null
        }
        {teamMembers[this.state.currentMemberId].cv
          ? <React.Fragment>
              <CVButton
                className="cvButton"
                onClick={this.openCV}>
                CV einblenden
              </CVButton>
          </React.Fragment>
        : null}
      </AboutMemberAnimate>
    )

    const aboutPartner = (
      <AboutPartnerAnimate
        pose={this.state.animatePartner ? 'hidden' : 'visible'}>
        <H3>{partnerMembers[this.state.currentPartnerId].name}</H3>
        <div>
          {partnerMembers[this.state.currentPartnerId].description}
        </div>
        {partnerMembers[this.state.currentPartnerId].linkToProfile
          ? <LinkedinWrapper>
            <a href={partnerMembers[this.state.currentPartnerId].linkToProfile} target="_blank" rel="noopener noreferrer">
              <LinkedinImage src={medkitIcon} alt="Linkedin" />
            </a>
          </LinkedinWrapper>
          : null
        }
      </AboutPartnerAnimate>
    )

    return (
      <React.Fragment>
        <Modal clicked={this.closeCV} show={this.state.showCV}>
          {teamMembers[this.state.currentMemberId].cv}
        </Modal>
        <span id="teamAnchor" className="anchor"></span>
        <StyledWrapper id="team" className="scrollSection">
          <MembersWrapper>
            <ResponsiveHeader>
              <h2>Team</h2>
            </ResponsiveHeader>
            <MainMembers>
              {members}
            </MainMembers>
            <AboutSection>
              <Tag tag="Team" />
              {aboutMember}
            </AboutSection>
          </MembersWrapper>
          <PartnersWrapper>
            <ResponsiveHeader>
              <h2 className="partnerHeader">Partner</h2>
            </ResponsiveHeader>
            <AboutPartners>
              <Tag tag="Partner" />
              {aboutPartner}
            </AboutPartners>
            <Partners>
              {partners}
            </Partners>
          </PartnersWrapper>
        </StyledWrapper>
      </React.Fragment>
    )
  }
}

export default TeamSection;
