import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../components/globalStyles';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/Header';
import HeroLearnAccordionWrapper from '../components/HeroLearnAccordionWrapper';
import TeamSection from '../components/TeamSection';
import BlogSection from '../components/BlogSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/footer';
import heroImage from '../images/hero.png';
import {initMap} from '../utils/utils'

const TitleSection = styled.div`
  margin: 160px 0 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

class IndexPage extends Component {
  componentDidMount() {
    window.google && initMap();
  }

  render() {
    return (
      <>
        <Helmet>
          <meta property="og:title" content="Aorten GefässZentrum Hirslanden Zürich" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://gefaess.ch/" />
          <meta property="og:image" content={heroImage} />
          <meta property="og:keywords" content="Gefässchirurgie, Aortenchirurgie, Gefässmedizin, Gefässspezialist Züricha" />
        </Helmet>
        <GlobalStyles smoothScroll />
        <Header />
        <Layout>
          <SEO
            title="Aorten GefässZentrum Hirslanden Zürich"
            description="Führendes Zentrum im Raum Zürich für Gefässmedizin und Gefässchirurgie in der Hirslanden Klinik im Park Zürich. Unter Leitung von Dr. Michael Szente Varga und Prof. Dr. Mario Lachat." />
          <div id="home" className="scrollSection">
            <TitleSection>
              <TagSection>
                <LineLeft />
                <Tag>Dr. med. Michael Szente Varga - Ihr Gefässspezialist in Zürich</Tag>
                <LineRight />
              </TagSection>
              <p>Je älter wir werden, desto mehr leiden unsere Blutgefässe. Mit der immer älter werdenden Bevölkerung erlangt die Behandlung von Gefässerkrankungen zunehmend an Bedeutung. Die moderne Gefässschirurgie bietet dafür eine breite Palette an Behandlungsmethoden, die individuell auf die Patienten und ihre Erkrankungen zugeschnitten werden. Neue Technologien erweitern das Therapiespektrum stetig. Zusammen mit meinen Partnern aus den Fachgebieten der Angiologie, Kardiologie und Herz- und Gefässchirurgie bilden wir das führende Netzwerk für Gefässmedizin im Raum Zürich.</p>
            </TitleSection>
          </div>
          <HeroLearnAccordionWrapper />
          <TeamSection />
          <BlogSection />
          <ContactSection />
        </Layout>
        <Footer />
      </>
    );
  }
}

export default IndexPage
