import React from 'react';
import styled from 'styled-components';

const TextOnImage = styled.div`
  position: absolute;
  background-color: rgba(32, 59, 188, 0.9);
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transform: ${props => props.active ? 'scale(1.08)' : ''};
  transition: .5s ease;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  height: 100%;
  width: auto;
  border-radius: 5px;
  transition: all 0.5s;
  transform: ${props => props.active ? 'scale(1.08)' : ''};

  @media(max-width: 500px) {
    height: auto;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  height: 240px;
  position: relative;
  cursor: pointer;
  margin-right: 20px;

  :hover ${TextOnImage} {
    opacity: 1;
  }

  @media(max-width: 1184px) {
    width: fit-content;
    height: auto;
    margin: 18px;
  }

  @media(max-width: 500px) {
    height: auto;
    width: 100%;
    margin: 0;
    :hover ${TextOnImage} {
      opacity: 0;
    }
  }
`;

const H5 = styled.h5`
  font-size: 18px;
  margin: 0 14px;
  text-align: center;
`;

const P = styled.p`
  font-size: 12px;
  line-height: 23px;
  color: #fff;
  text-align: center;
  margin: 0 22px;
`;

const hoverImage = props => (
  <ImageContainer
    onClick={props.onClick}>
    <Img
      src={props.srcImage}
      alt={props.imageAltText}
      active={props.active} />
    <TextOnImage active={props.active}>
      <H5>{props.title}</H5>
      <P>{props.text}</P>
    </TextOnImage>
  </ImageContainer>
);

export default hoverImage;
